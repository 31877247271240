import React from "react"
import { FaRegBell, FaHome, FaCheck, FaRegSun, FaShopify, FaUserTag, FaUserTie, FaListUl, FaBook, FaRegClipboard, FaTicketAlt } from 'react-icons/fa'
// import { AiFillNotification, AiFillDatabase, AiOutlineOrderedList, AiOutlineUsergroupAdd } from 'react-icons/ai'
const user = JSON.parse(sessionStorage.getItem('user'))

const navigationSuperAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "gsistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sistema1",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users"
      }
    ]
  },
 {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  },
];

const navigationUser = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  }
];

const navigationShopping = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompra",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscomunicaciones",
      },
    ]
  }
];

const navigationComunicaciones = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompras",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets-comunicaciones",
      },
    ]
  }
];


const navigationConfig =
  user?.profile === 2 ? navigationSuperAdmin :
    user?.profile === 3 ? navigationUser :
      user?.profile === 65 ? navigationShopping :
        user?.profile === 75 ? navigationComunicaciones :
            []

export default navigationConfig
